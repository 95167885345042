import React, { Fragment } from "react";
import { StyledSeasonInfo, StyledDivider } from "./style";
import { useTranslation } from "react-i18next";
import { Leaderboard } from "../../../services/championsQueueData/index.type";
import { Times } from "../../../helpers/utils";

export type Props = {
  className?: string
  leaderboard: Leaderboard
};

const SeasonInfo: React.FC<Props> = ({ className, leaderboard }: Props) => {
  const { t } = useTranslation();

  const { split } = leaderboard;

  return (
    <StyledSeasonInfo className={ className }>
      {leaderboard.title}
      {split && (
        <Fragment>
          <StyledDivider/>
          {split.title}
        </Fragment>
      )}
      <StyledDivider/>
      {t(
        "leaderboard:numberDaysRemaining",
        { count: numberDaysRemaining(Date.parse((split || leaderboard).closeDate)) }
      )}
    </StyledSeasonInfo>
  );
};

const numberDaysRemaining = (until: number): number => {
  const now = Date.now();

  return Math.max(0, Math.ceil((until - now) / Times.Day));
};

export default SeasonInfo;
