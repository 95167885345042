import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { useGetRankWithSuffix } from "../../../hooks/use-get-rank-with-suffix";
import { StyledLeadingChampions } from "./style";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image-es5";
import SeasonInfo from "../../Seasons/Info";
import { Leaderboard } from "../../../services/championsQueueData/index.type";
import { renderPlayerName } from "../PlayerName";

export type Props = {
  current: Leaderboard
};

const LeadingChampions: React.FC<Props> = ({ current }: Props) => {
  const { t } = useTranslation();
  const getRankWithSuffix = useGetRankWithSuffix();

  const { patternImage } = useStaticQuery(
    graphql`
      query {
        patternImage: file(relativePath: { eq: "title-pattern.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
    `
  );
  const image = getImage(patternImage);
  const bgImage = convertToBgImage(image);

  const first = current.lineup[0];
  const rest = current.lineup.slice(1, 5);

  return (
    <StyledLeadingChampions>
      <div className="top-row">
        <BackgroundImage Tag="div" className="title-block" {...bgImage}>
          <div className="leading-champions-title">
            {t("leaderboard:leadingChampions")}
          </div>
          <SeasonInfo leaderboard={current} />
        </BackgroundImage>
        <div className="first-block">
          <div className="rank">{getRankWithSuffix(1)}</div>
          <div className="player-name">{renderPlayerName(first.name)}</div>
          <div className="season-points">
            {t("labels.seasonPoints", { value: first.seasonPoints })}
          </div>
        </div>
      </div>
      <div className="bottom-row">
        {rest.map((player) => (
          <div key={player.name} className="bottom-row-player">
            <div className="rank">{getRankWithSuffix(player.rank)}</div>
            <div className="player-name">{renderPlayerName(player.name)}</div>
            <div className="season-points">
              {t("labels.seasonPoints", { value: player.seasonPoints })}
            </div>
          </div>
        ))}
      </div>
    </StyledLeadingChampions>
  );
};

export default LeadingChampions;
