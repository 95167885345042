import styled from "styled-components";
import { device, StyleFontHeader, TextTransformUppercase } from "../../Layout/theme";

export const StyledLeadingChampions = styled.section`
  .top-row {
    display: flex;
    align-items: stretch;
  }

  .title-block {
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .leading-champions-title {
      ${StyleFontHeader}
      font-size: 94px;
      line-height: 94px;
      letter-spacing: 0.01em;
    }
  }

  .first-block {
    background: radial-gradient(52.65% 299.07% at 105.34% 107.69%, #EFC154 0%, #CB9516 100%);
    color: ${({ theme }) => theme.color_text_dark};
    width: 42%;
    flex-grow: 0;

    .rank {
      ${TextTransformUppercase}
      font-size: 80px;
      line-height: 80px;
      font-weight: bold;
    }
  }

  .bottom-row {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
  }

  .bottom-row-player {
    width: 25%;
    background-color: ${({ theme }) => theme.color_background_tertiary};

    .rank {
      ${TextTransformUppercase}
      font-size: 28px;
      font-weight: bold;
    }
  }

  .player-name {
    font-weight: bold;
    font-size: 18px;
  }

  .season-points {
    ${TextTransformUppercase}
  }

  @media ${device.large} {
    margin: ${({ theme }) => theme.gutter_size_large};

    .title-block,
    .first-block,
    .bottom-row-player {
      padding: ${({ theme }) => theme.gutter_size_large};
    }
  }

  @media ${device.medium} {
    margin: ${({ theme }) => theme.gutter_size_medium};

    .title-block,
    .first-block,
    .bottom-row-player {
      padding: ${({ theme }) => theme.gutter_size_medium};
    }
  }

  @media ${device.small} {
    margin: ${({ theme }) => theme.gutter_size_small};

    .title-block {
      .leading-champions-title {
        font-size: 64px;
        line-height: 64px;
      }
    }

    .first-block {
      .rank {
        font-size: 50px;
      line-height: 50px;
      }
    }

    .top-row,
    .bottom-row {
      flex-direction: column;
    }

    .first-block,
    .bottom-row-player {
      width: 100%;
      flex-grow: 1;
    }

    .title-block,
    .first-block,
    .bottom-row-player {
      padding: ${({ theme }) => theme.gutter_size_small};
    }
  }
`;
