import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { StyledLeaderboardFeature } from "./style";

const LeaderboardFeature: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <StyledLeaderboardFeature>
      <div className="images">
        <StaticImage
          className="image"
          src="../../../images/kaisa.png"
          alt={t("champions.kaisa")}
        />
        <StaticImage
          className="image"
          src="../../../images/lucian.png"
          alt={t("champions.lucian")}
        />
      </div>
      <div className="title">
        <StaticImage
          className="image"
          src="../../../images/CQ_WHITE_2.png"
          alt={t("nav.logo")}
        />
      </div>
    </StyledLeaderboardFeature>
  );
};

export default LeaderboardFeature;
